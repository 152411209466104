@import './colors.scss';


.container{
    display: grid;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: 100%;
    grid-template-rows: min-content 1fr max-content 12px;
    grid-row-gap: 16px;
    align-items: center;
    max-height: none !important;
    min-height: 100vh;
}

.container .pageTitle{
    font-family: Roboto;
    font-weight: 800;
    font-size: 48px;
    color: $baseColorDark;
    margin: auto;
    margin-bottom: 20px;
    border-bottom: 4px groove $baseColorDark;
    width: 35%;
    min-width: 200px;
    align-self: flex-start;
    vertical-align: top;
    height: 100%;
}

.container .cardImage{
    max-width: 360px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin: auto;
}

.container .cardContent{
    height: 22vh;
    overflow: hidden;
}

.container .cardDescription{
    overflow: hidden;
}


.container h1{
    font-family: Montserrat, Roboto;
    font-size: 38px;
    font-weight: bolder;
    padding: 8px;
}

.container h1 .color1{
    color: #BD4F20;
}


.coursesWrapper :global .slick-slider{
    margin: 25px;
}


.fadeOverlay{
    //z-index: 100;
    pointer-events: none;
    box-shadow: 0px -28px 50px -7px #FFFFFF;
    height: 55px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.container .myButton{
    background-color: $baseColorDark;
    grid-row: 3 / span 1;
    display: block;
    padding: 8px 16px;
    text-align: center;
    font-size: 16px;
    text-decoration: none !important;
    color: white !important;
    border-radius: 8px;
    max-width: 500px;
    margin-top: 16px;
    cursor: pointer;
    transition: all ease-in-out 0.4s;
}

.container .myButton:hover, .container .myButton:active{
    transform: scale(1.1, 1.1);
    background-color: $orangeLight;
}

.container .myButtonGroup{
    display: flex;
    overflow: hidden;
    height: fit-content;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    
    min-height: 60px;
    line-height: 48px;
    font-size: 24px;
}

.container .myButtonGroup .myButton{
    height: 100%;
    width: 100%;
    font-size: inherit;
    justify-self: center;
}

@media screen and (max-width: 900px) {
    .container{
        grid-template-columns: 100%;
    }
    .container .allNews{
      display: none;
    }
    .container .bigNews{
        padding: 15px;
        margin: auto;
    }
    .container .myButtonGroup{
        flex-direction: column;
        justify-content: center;
    }
  }
