@import './colors.scss';

.pageTop, .pageMid, .pageBot, .pageDefault{
    margin: 32px 0;
    padding: 86px 64px;

    min-height: 80vh;
    background-color: #add8e6e3;
}

@media screen and (max-width: 900px) {
    .pageTop, .pageMid, .pageBot, .pageDefault{
        margin: 32px 0;
        padding: 86px 8px;
    }
}

.first{
    margin-top: 84px;
}

.pageTop{
    margin-top: 70px;
    //padding-top: 64px;
    clip-path: polygon(0% 0%, 100% 0%,  100% calc(100% - 64px), calc(50% + 64px) calc(100% - 64px), 50% 100%, calc(50% - 64px) calc(100% - 64px), 0% calc(100% - 64px));
}

.pageBot{
    min-height: auto;
    height: fit-content;
    clip-path: polygon(0% 0%,  calc(50% - 64px) 0%,  50% 64px,  calc(50% + 64px) 0%, 100% 0%, 100% 100%, 0% 100%);
}

.pageMid{
    clip-path: polygon(0% 0%,  calc(50% - 64px) 0%,  50% 64px,  calc(50% + 64px) 0%, 100% 0%, 100% calc(100% - 64px), calc(50% + 64px) calc(100% - 64px), 50% 100%, calc(50% - 64px) calc(100% - 64px), 0% calc(100% - 64px));
}

.pageDefault{
    min-height: 80vh;
}

.wrapper{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.container{
    width: 100%;
    height: 100%;
}

.segmentTitle{
    font-size: 42px;
    font-weight: bold;
    line-height: 42px;
    padding: 8px;
    width: fit-content;
    margin: auto;
    margin-bottom: 20px;
    color: $baseColorDark;
    border-bottom: 4px groove $baseColorDark;
}

.orange .segmentTitle{
    color: $orangeMedium;
}