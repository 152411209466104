@import './colors.scss';


.container{
    display: grid;
    position: relative;
    box-sizing: border-box;
    grid-template-rows: min-content 1fr 8px;
    grid-row-gap: 16px;
    grid-template-columns: 50% 50%;
    align-items: center;
    min-height: 100vh;
    height: max-content;
    padding-bottom: 100px;
}

.container .pageTitle{
    //position: absolute;
    grid-column: 1 / span 2;
    font-family: Roboto;
    font-weight: 800;
    font-size: 48px;
    margin: auto;
    margin-bottom: 20px;
    border-bottom: 4px groove $baseColorDark;
    width: 35%;
    min-width: 200px;
    align-self: flex-start;
    vertical-align: top;
    height: 100%;
}

.container .bigNews{
    padding-right: 10px;
    margin-right: 15px;
}

.container .allNews{
    margin-left: 15px;
    padding-left: 10px;
    padding-right: 20px;
   // border-left: 5px groove #2D2D2D;
    font-family: Roboto;
    // align-self: center;
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 72vh;
}

.container h1, .container h2{
    font-family: Montserrat, Roboto;
}

.container h1 .color1{
    color: #BD4F20;
}
.container h1 .color2{
    color: #BD4F20;
}

.container h1{
    font-size: 28px;
    font-weight: bolder;
    padding: 8px;
}
.container h2{
    font-size: 24px;
    font-weight: bold;
}

.container p{
    font-size: 18px;
}


.container :global .myButton{
    background-color: $baseColorDark;
    display: block;
    padding: 8px 16px;
    text-align: center;
    font-size: 24px;
    color: white;
    border-radius: 8px;
    max-width: 80%;
    margin: auto;
    cursor: pointer;
    transition: all ease-in-out 0.4s !important;
}

.container :global .myButton.small{
    width: max-content;
    font-size: 18px;
    margin-top: 20px;
}

.container :global .myButton:hover, .container :global .myButton:active{
    transform: scale(1.2, 1.1);
    background-color: $orangeLight;
}

@media screen and (max-width: 900px) {
    .container{
        grid-template-columns: 100%;
        height: 150vh;
    }
    .container .allNews{
      display: none;
    }
    .container .bigNews{
        padding: 15px;
        margin: auto;
    }
  }


.placeholderImg{
    object-fit: none;
    // background-color: #D2D2D2;
    background-image: url('../assets/loading1.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px;
    height: 300px;
}

.smallNews{
    background-color: $baseColorLight;
}

.card{
    padding: 8px;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    background-color: ivory;
    img{
        width: 256px;
        display: block;
        flex-basis: 256px;
        border-radius: 5px;
    }
    .head{
        display: flex;
        flex-flow: row wrap;
        justify-content:safe;
        align-items: stretch;
        align-content: stretch;
        width: 100%;
    }
    .column{
        padding-left: 8px;
        flex-grow: 1;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: left;
    }
    p{
        padding: 12px;
        font-size: 1.2rem;
        text-align: justify;
    }
    *:global(.myButton){
        font-size: 1.2rem;
        width: fit-content;
        margin: 0px;
    }
}
