@import './colors.scss';


.container{
    display: grid;
    box-sizing: border-box;
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 1fr minmax(100px, 1fr) 1fr;
    row-gap: 16px;
    align-items: center;
    width: 100%;
}

.container .pageTitle{
    grid-column: 1 / -1;
    font-family: 'Playfair Display SC', serif;
    font-weight: 700;
    color: $baseColorDark;
    font-size: 40px;
    line-height: 40px;
    margin: auto;
    margin-bottom: 20px;
    border-bottom: 4px groove $baseColorDark;
    width: fit-content;
    min-width: 200px;
    align-self: flex-start;
    vertical-align: top;
    height: 100%;
}

.container .slider{
    display: block;
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
    height: 100%;
    overflow: hidden;
}
.container .slider :global *{
    max-height: 100% !important;
    height: 100%;
}
.container .slider img{
    display: block;
    border-radius: 50%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
    width: auto !important;
    max-width: 100%;
}

.container .circle{
    width: 48%;
    padding: 8px;
    display: inline-block;
}

.container .cards{
    grid-column: 1 / -1;
    width: 100%;
}

.container .cards :global .ui.cards .ui.card .content .header{
    font-size: 20px;
    font-family: Roboto;
}
.container .cards :global .ui.cards .ui.card .content .description{
    font-size: 16px;
    font-family: Montserrat;
}

.container span.color1{
    color: #BD4F20;
}

.container span.small{
    //font-size: 80%;
    padding-left: 8px;
    padding-right: 8px;
}

.container .right p{
    font-size: 22px;
}

@media screen and (max-width: 1024px) {
    .container{
        grid-template-rows: min-content min-content 2fr;
        grid-template-columns: 50% 50%;
        max-height: none;
    }
    
    .container .slider{
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .container{
        grid-template-rows: min-content min-content 2fr;
        grid-template-columns: 100%;
        max-height: none;
    }
    .container .circle{
        width: 40%;
    }
  }