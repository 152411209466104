@import './colors.scss';

.myButton{
    background-color: $baseColorDark;
    grid-row: 3 / span 1;
    display: block;
    padding: 8px 16px;
    text-align: center;
    font-size: 16px;
    text-decoration: none !important;
    color: white !important;
    border-radius: 8px;
    max-width: 500px;
    margin-top: 16px;
    cursor: pointer;
    transition: all ease-in-out 0.4s;
}

.myButton:hover, .myButton:active{
    transform: scale(1.1, 1.1);
    background-color: $orangeLight;
}

.cardImage{
    max-width: 360px;
    width: 100%;
    height: 256px;
    object-fit: cover;
    margin: auto;
}

:global .ui.steps:local(.stepBG) .step{
    background-color: white;
    background-image: linear-gradient(90deg, $baseColorLight 0%, rgba(255,255,255,1) 76%);
}
