@import './colors.scss';


.container{
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 50% 50%;
    //justify-items: center;
    align-items: center;
    
}

.container :global img{
    max-height: 70vh;
    max-width: 70vh;
    border-radius: 8px;
}

.container .left{
    padding-right: 10px;
    margin-right: 15px;
}

.container .right{
    // justify-self: center;
    margin-left: 15px;
    padding-left: 10px;
    border-left: 5px groove #2D2D2D;
    font-family: Roboto;
    text-align: left;
    
}

.container h1{
    font-family: Montserrat, Roboto;
    font-size: 38px;
    font-weight: bolder;
    padding-bottom: 16px;
    max-width: 500px;
}

.container h1 .color1{
    color: #BD4F20;
}
.container h1 .color2{
    color: #BD4F20;
}

.container .right p{
    font-size: 22px;
}

.container .right>ul>li{
    font-size: 18px;
}

.container .right :global .myButton{
    background-color: $baseColorDark;
    display: block;
    //width: fit-content;
    padding: 16px 32px;
    text-align: center;
    font-size: 32px;
    color: white;
    border-radius: 8px;
    max-width: 500px;
    // margin: auto;
    margin-top: 32px;
    cursor: pointer;
    transition: all ease-in-out 0.4s;
}

.container .right :global .myButton:hover, .container .right :global .myButton:active{
    transform: scale(1.2, 1.1);
    background-color: $orangeLight;
}

@media screen and (max-width: 900px) {
    .container{
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        justify-items: center;
    }
    .container .left{
      display: none;
    }
  }