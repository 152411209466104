
$baseColorDark: #085A4F;
$baseColorLight:  #00BDA4;
$baseColorNormal: #41A698;
$myGreenLightest: #16F2D5;

$navheight: 72px;

.navbar{
    padding: 0px !important;
    padding-right: 32px !important;
    margin: 0px;
    background-image: linear-gradient(white, white),url('../assets/circuitBG.jpg');
    background-size: 64%;
    background-blend-mode: soft-light;
    width: 100vw;
}

.navbar a{
    padding: 0px;
}

.navbar .navBrand img{
    //display: inline-block;
    margin: 0px;
    height: $navheight;
    clip-path: polygon(0% 0%, 36% 0%, 41% 50%, 36% 100%, 0px 100%);
    transition: clip-path 0.4s ease;
}

.navbar .navBrand:hover img, .navbar .navBrand:active img{
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%);
}

.navContainer{
    filter: none;
}

.navbar .navItem{
    font-family: Roboto;
    font-size: 1.2em;
    font-weight: bold;
    transition: all 0.15s ease;
    height: $navheight;
    line-height: $navheight;
    vertical-align: middle;
}

.navbar .navItem:hover{
    line-height: calc(#{$navheight} - 14px);
}

.navbar .navItem span{
    vertical-align: middle;
    display: inline-block;
    color: $baseColorDark;
}

.navbar .navItem:hover, .navbar .navItem.active {
    border-top: 4px solid $baseColorDark;
    border-bottom: 4px solid $baseColorDark;
}

.navbar :global .navbar-toggler{
    background-color: $baseColorDark;
    color: white;
}

@media screen and (max-width: 700px){
    .navbar .navBrand img{
        clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%);
    }
}