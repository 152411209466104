@import './colors.scss';

.container{
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 50% 50%;
   
    align-items: center;
}

.container :global img{
    max-height: 40vh;
    max-width: 40vh;
    border-radius: 8px;
    margin: auto;
}

.container .left{
    padding-right: 10px;
    margin-right: 15px;
}

.container .right{
    // justify-self: center;
    padding-left: 36px;
    border-left: 5px groove $baseColorDark;
    font-family: Roboto;
    text-align: left;
    color: #2D2D2D;
}

.container h1{
    font-family: 'Playfair Display SC', serif;
    color: $baseColorDark;
    font-size: 38px;
    font-weight: bolder;
    padding-bottom: 16px;
    max-width: 500px;
}

.container h1 .color1{
    color: #BD4F20;
}
.container h1 .color2{
    color: #BD4F20;
}

.container .right :global .item:hover{
    color: $baseColorDark;
}

@media screen and (max-width: 900px) {
    .container{
        grid-template-columns: 100%;
        justify-items: center;
    }
    .container .left{
      display: none;
    }
  }