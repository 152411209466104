@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500&family=Roboto:ital,wght@0,400;0,700;0,900;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap');


body{
    overflow: auto;
    width: 100vw;
    overflow-x: hidden !important;
}

h1{
    color: #2D2D2D;
}

.page{
    max-width: 1860px;
    min-height: 100vh;
    width: 100%;
    overflow:initial;
    margin: auto;
}

.proba{
    position: sticky;
    top: 512px;
}

.myButton{
    text-decoration: none !important;
    color: white !important;
}