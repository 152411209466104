@import './colors.scss';


.slider :global .slick-center :local(.slide){
    transform: scale(1.5, 1.5);
    z-index: 200;
    transition: transform 0.2s, filter 0.5s;
    transition-timing-function: ease-in-out;
    filter: none
}

.slider .slide{
    height: 50vh;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
   // filter: blur(4px);
}

.slider .slide img, .slider .slide video{
    display: block;
    object-fit: contain;
    object-position: center;
    //max-width: 200px;
    // min-width: 500px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    border-radius: 5px;
}

.divided{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    align-items: center;
    column-gap: 32px;
}

.divided .left{
    width: max-content;
    max-width: 100%;
    justify-self: end;
}

.divided .right{
    width: max-content;
    max-width: 100%;
    justify-self: start;
}

.divided h1{
    font-family: Montserrat;
    font-weight: bold;
    color: $baseColorDark;
    font-size: 48px;
    max-width: 500px;
    text-align: right;
    border-right: 4px solid $baseColorDark;
    padding: 24px;
}

.articleBody{
    font-size: 18px;
    font-family: Roboto;
    color: #2D2D2D;
    text-align: left;
    margin: auto;
    padding: 20px;
    text-align: justify;
    width: max-content;
    max-width: 100%;
    overflow: auto;
    *{
        max-width: 100%;
    }
}

.articleBody{
    p, h1, h2, h3, h4, ul, ol, li, img{
        max-width: 700px;
    }
    strong{
        color: $orangeLight;
    }
    img{
        display: block;
        width: 100%;
    }
    iframe{
        width: 100%;
        *width: 100%;
        max-width: 90vw;
    }
}

.articleBody h3{
    text-indent: 40px;
}
.articleBody h3 li{
    text-indent: 0px;
}


.articleBody h1, .articleBody h2, .articleBody h3{
    padding: 16px;
    max-width: 700px;
}

.articleBody h1{
    font-size: 32px;
}

.banner{
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 32px;
}
.banner h1{
    font-size: 64px;
    font-weight: bold;
}
.banner h2{
    font-size: 32px;
    font-weight: 500;
}
.banner .background{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -100;
    filter: blur(1px) contrast(50%);
}

.banner .foreground{
    position: relative;
    display: block;
    max-width: 100%;
    width: max-content;
    height: max-content;
    padding: 64px 40px;
    text-shadow: 2px 2px 2px #34201B;
    margin: auto;
    h1, h2{
        color: $orangeLight;
    }
    .overlay{
        position: absolute;
        top:0;
        left:50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        background-color: $baseColorLight;
        mix-blend-mode: hard-light;
        opacity: 0.5;
        z-index: -50;
    }
}


.myButton{
    background-color: $baseColorDark;
    display: block;
    text-decoration: none !important;
    padding: 16px 32px;
    text-align: center;
    font-size: 32px;
    color: white !important;
    border-radius: 8px;
    max-width: 500px;
    margin-top: 32px;
    cursor: pointer;
    transition: all ease-in-out 0.4s;
}

.myButton:hover, .myButton:active{
    transform: scale(1.2, 1.1);
    background-color: $orangeLight;
}

.myButtonGroup{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 32px 10px;
    overflow: hidden;
}

.myButtonGroup:global(.vertical){
    flex-direction: column;
    align-items: center;
    padding: 10px 32px;
}

.myButtonGroup:global(.vertical) .myButton{
    width: 100%;
    max-width: 700px;
    padding: 16px 16px;
    margin-top: 16px;
}



@media screen and (max-width: 900px) {
    .divided{
        grid-template-rows: 50% 50%;
        grid-template-columns: 100%;
    }
    .divided h1, .banner h1{
        font-size: 42px;
    }
    .divided .left, .divided .right{
        justify-self: center;
    }
    .myButton{
        font-size: 24px;
    }
}


:global table.orar{
    width: 100%;
    background-color: #fff;
    text-align: center;
    border-collapse: collapse;
    cursor: default;
    th, td {
    font-weight: unset;
    padding-right: 10px;
    border: 1px solid #3D3D3D;
    }
    
    td {
    max-width: 228px;
    padding: 20px;

    font-family: Montserrat-Regular;
    font-weight: normal;
    font-size: 15px;
    color: #808080;
    line-height: 1.4;
    }
    
    // td:first-child, th {
    // width: 265px;
    // padding-left: 42px;
    // }
    
    tr th {
    padding: 20px;

    font-family: Montserrat-Medium;
    font-size: 12px;
    color: #fff;
    line-height: 1.4;
    text-transform: uppercase;

    background-color: #333333;
    }
    
    tr td {
        padding-top: 18px;
        padding-bottom: 14px;
    }
    tr:hover td{
        background-color: $baseColorNormal;
        color: #fff;
    }
    tr td:hover{
        background-color: $baseColorDark;
        color: #fff;
    }
}