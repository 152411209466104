.svgContainer{
    margin: auto;
    max-height: 100vh;
}

.svgContainer .linkLayer{
    cursor: pointer;
}

.svgContainer .linkLayer .linkIconColor{
    opacity: 0;
    transition: opacity 0.5s;
}

.svgContainer .linkLayer:hover .linkIconColor{
    opacity: 1;
}

.svgContainer .linkLayer .linkBranch *{
    fill: #085a4f;
}

.svgContainer .linkLayer:hover .linkBranch.left *{
    fill: url(#loadingGradientLeft)
}
.svgContainer .linkLayer:hover .linkBranch.right *{
    fill: url(#loadingGradientRight)
}


#inner_circle {
    animation-name: rotateLeft;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-box: fill-box;
    transform-origin: center;
}

#middle_circle {
    animation-name: rotateRight;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-box: fill-box;
    transform-origin: center;
}

// #outer_circle {
//     //animation-name: rotateLeft;
//     animation-duration: 4s;
//     animation-timing-function: linear;
//     animation-iteration-count: infinite;
//     transform-box: fill-box;
//     transform-origin: center;
// }

@keyframes rotateLeft{
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
@keyframes rotateRight{
    0% {transform: rotate(0deg);}
    100% {transform: rotate(-360deg);}
}




.svgContainer .cls-1, .svgContainer .cls-32, .svgContainer .cls-7, .svgContainer .cls-71 {
    fill: none;
  }

.svgContainer .cls-2 {
    isolation: isolate;
}

.svgContainer .cls-3 {
    fill: url(#radial-gradient);
}

.svgContainer .cls-4 {
    opacity: 0.45;
    mix-blend-mode: saturation;
}

.svgContainer .cls-5 {
    clip-path: url(#clip-path);
}

.svgContainer .cls-38, .svgContainer .cls-6 {
    fill: #085a4f;
}

.svgContainer .cls-7, .svgContainer .cls-71 {
    stroke: #fff;
}

.svgContainer .cls-7 {
    stroke-width: 2.51988px;
}

.svgContainer .cls-10, .svgContainer .cls-11, .svgContainer .cls-12, .svgContainer .cls-13, .svgContainer .cls-14, .svgContainer .cls-15, .svgContainer .cls-16, .svgContainer .cls-17, .svgContainer .cls-18, .svgContainer .cls-19, .svgContainer .cls-20, .svgContainer .cls-21, .svgContainer .cls-22, .svgContainer .cls-23, .svgContainer .cls-24, .svgContainer .cls-25, .svgContainer .cls-26, .svgContainer .cls-27, .svgContainer .cls-28, .svgContainer .cls-29, .svgContainer .cls-30, .svgContainer .cls-31, .svgContainer .cls-72, .svgContainer .cls-73, .svgContainer .cls-74, .svgContainer .cls-75, .svgContainer .cls-76, .svgContainer .cls-77, .svgContainer .cls-78, .svgContainer .cls-79, .svgContainer .cls-8, .svgContainer .cls-80, .svgContainer .cls-81, .svgContainer .cls-82, .svgContainer .cls-83, .svgContainer .cls-84, .svgContainer .cls-85, .svgContainer .cls-86, .svgContainer .cls-87, .svgContainer .cls-88, .svgContainer .cls-89, .svgContainer .cls-9, .svgContainer .cls-90, .svgContainer .cls-91, .svgContainer .cls-92, .svgContainer .cls-93, .svgContainer .cls-94, .svgContainer .cls-95, .svgContainer .cls-96, .svgContainer .cls-97, .svgContainer .cls-98 {
    fill: #fff;
}

.svgContainer .cls-10, .svgContainer .cls-11, .svgContainer .cls-12, .svgContainer .cls-13, .svgContainer .cls-14, .svgContainer .cls-15, .svgContainer .cls-16, .svgContainer .cls-17, .svgContainer .cls-18, .svgContainer .cls-19, .svgContainer .cls-20, .svgContainer .cls-21, .svgContainer .cls-22, .svgContainer .cls-23, .svgContainer .cls-24, .svgContainer .cls-25, .svgContainer .cls-26, .svgContainer .cls-27, .svgContainer .cls-28, .svgContainer .cls-29, .svgContainer .cls-30, .svgContainer .cls-72, .svgContainer .cls-73, .svgContainer .cls-74, .svgContainer .cls-75, .svgContainer .cls-76, .svgContainer .cls-77, .svgContainer .cls-78, .svgContainer .cls-79, .svgContainer .cls-8, .svgContainer .cls-80, .svgContainer .cls-81, .svgContainer .cls-82, .svgContainer .cls-83, .svgContainer .cls-84, .svgContainer .cls-85, .svgContainer .cls-86, .svgContainer .cls-87, .svgContainer .cls-88, .svgContainer .cls-89, .svgContainer .cls-9, .svgContainer .cls-90, .svgContainer .cls-91, .svgContainer .cls-92, .svgContainer .cls-93, .svgContainer .cls-94, .svgContainer .cls-95, .svgContainer .cls-96, .svgContainer .cls-97, .svgContainer .cls-98 {
    font-family: PlayfairDisplay-Black, Playfair Display;
    font-weight: 800;
}

.svgContainer .cls-9 {
    font-size: 25.79134px;
}

.svgContainer .cls-24 {
    font-size: 26.14328px;
}

.svgContainer .cls-32 {
    stroke: #085a4f;
    stroke-miterlimit: 10;
    stroke-width: 6px;
}

.svgContainer .cls-33 {
    fill: #aecef2;
}

.svgContainer .cls-34 {
    fill: #f0f6fe;
}

.svgContainer .cls-35 {
    fill: #c9dff7;
}

.svgContainer .cls-36 {
    fill: #606a70;
}

.svgContainer .cls-37 {
    fill: #414952;
}

.svgContainer .cls-38 {
    font-size: 43.32204px;
    font-family: Roboto-Bold, Roboto;
    font-weight: 700;
}

.svgContainer .cls-39 {
    letter-spacing: -0.01026em;
}

.svgContainer .cls-40 {
    fill: #ff8e1d;
}

.svgContainer .cls-41 {
    fill: #ffb023;
}

.svgContainer .cls-42 {
    fill: #ffd12b;
}

.svgContainer .cls-43 {
    fill: #ff6d15;
}

.svgContainer .cls-44 {
    fill: url(#linear-gradient);
}

.svgContainer .cls-45 {
    fill: #f7d360;
}

.svgContainer .cls-46 {
    fill: #ffdbcc;
}

.svgContainer .cls-47 {
    fill: #fc5f61;
}

.svgContainer .cls-48 {
    fill: url(#linear-gradient-13);
}

.svgContainer .cls-49 {
    fill: url(#linear-gradient-14);
}

.svgContainer .cls-50 {
    fill: #ffbf9e;
}

.svgContainer .cls-51 {
    fill: #ffd4be;
}

.svgContainer .cls-52 {
    fill: #91def5;
}

.svgContainer .cls-53 {
    fill: #f6838d;
}

.svgContainer .cls-54 {
    fill: url(#selectgr);
}

.svgContainer .cls-55 {
    fill: url(#selectgr-2);
}

.svgContainer .cls-56 {
    fill: url(#selectgr-3);
}

.svgContainer .cls-57 {
    fill: url(#selectgr-4);
}

.svgContainer .cls-58 {
    fill: url(#selectgr-5);
}

.svgContainer .cls-59 {
    fill: #4db7e5;
}

.svgContainer .cls-60 {
    fill: #48add9;
}

.svgContainer .cls-61 {
    fill: #e0e0de;
}

.svgContainer .cls-62 {
    fill: #c7c7c5;
}

.svgContainer .cls-63 {
    fill: #7a432a;
}

.svgContainer .cls-64 {
    fill: #fec9a3;
}

.svgContainer .cls-65 {
    fill: #feb784;
}

.svgContainer .cls-66 {
    fill: #f35244;
}

.svgContainer .cls-67 {
    fill: #ea4a3b;
}

.svgContainer .cls-68 {
    fill: #fec093;
}

.svgContainer .cls-69 {
    letter-spacing: -0.00928em;
}

.svgContainer .cls-70 {
    clip-path: url(#clip-path-5);
}

.svgContainer .cls-71 {
    stroke-width: 0.73868px;
}

.svgContainer .cls-72 {
    font-size: 7.56053px;
}

.svgContainer .cls-91 {
    font-size: 7.6637px;
}
